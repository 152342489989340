<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="page--new-password d-flex justify-center align-center">
          <div class="form-container">
            <v-card class="pa-10" color="rgba(255, 255, 255, .8)">
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col class="text-center">
                    <img
                      src="/img/brasao_64.png"
                      alt="Brasão do state de Pernambuco"
                    />
                    <br />
                    <h1 class="subtitle-1 blue--text text--darken-4">
                      Nova Senha
                    </h1>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      solo
                      hide-details="auto"
                      placeholder="Nova senha"
                      type="password"
                      :rules="passwordRules"
                      :disabled="!isAccessValid"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      block
                      color="success"
                      :loading="loading"
                      @click="changePassword()"
                      :disabled="!isAccessValid"
                    >
                      Mudar Senha
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0 mt-3 text-center">
                    <small>
                      <router-link :to="{ path: '/EsqueciASenha' }">
                        Esqueci a senha
                      </router-link>
                    </small>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import configurations from "commons/configurations";
import pageLoadingMixin from "mixins/page-loading.mixin";
import notificationMixin from "mixins/notification.mixin";
import Validations from "services/validations/validations.js";

export default {
  name: "NewPassword",
  components: {},
  mixins: [pageLoadingMixin, notificationMixin],
  data: () => ({
    valid: false,
    loading: false,
    user: {
      username: undefined,
      password: undefined,
    },
    token: undefined,
  }),
  computed: {
    passwordRules() {
      return [
        (value) => Validations.required(value, "Senha é obrigatória"),
        (value) => Validations.min(value, 2, "Senha deve ter pelo menos 2 caracteres"),
      ];
    },
    isAccessValid() {
      return (
        this.$route.params &&
        this.$route.params.username &&
        this.$route.params.token
      );
    },
  },
  beforeMount() {
    this.hidePageLoading();
  },
  mounted() {
    if (this.isAccessValid) {
      this.setMetadata();
    } else {
      this.notifyError("Usuário ou token não identificado.");
    }
  },
  methods: {
    changePassword() {
      this.$refs.form.validate();
      
      if(!this.valid) {
        return;
      }

      if (!this.isAccessValid) {
        return;
      }

      this.loading = true;

      this.$api()
        .post(configurations.api.auth.newPasswordUri, {
          nomeDeUsuario: this.user.username,
          senha: this.user.password,
          token: this.token,
        })
        .then(({ data }) => {
          if (data.sucesso) {
            this.notifySuccess(data.mensagem);
            this.$router.push({ path: "/login" });
          } else {
            this.loading = false;
            this.notifyError(data.mensagem);
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError(
              "Não conseguimos nos comunicar com o servidor da aplicação."
            );
          }
        });
    },
    setMetadata() {
      this.user.username = this.$route.params.username;
      this.token = this.$route.params.token;
    },
  },
};
</script>

<style lang="scss" scoped>
.page--new-password {
  min-height: calc(100vh - 24px) !important;
}
</style>
